<mat-list>

  <mat-list-item routerLink="{{routes.DASHBOARD}}" routerLinkActive="active" (click)="side_redirect('dashboard')" *ngIf="user_type=='SA' || user_type=='CA' || user_type=='CV'">
    <img src="./assets/images/dashboard_icon.png" alt="" title="Dashboard"><span>Dashboard</span>
  </mat-list-item>

  <mat-list-item routerLink="/customer" routerLinkActive="active" (click)="side_redirect('customer')" *ngIf="user_type=='C'">
    <!-- <a>
      <mat-icon title="Dashboard">signal_cellular_alt</mat-icon> <span>Dashboard</span>
    </a> -->
    <img src="./assets/images/dashboard_icon.png" alt="" title="Approval Tool"><span>Dashboard</span>
  </mat-list-item>

  <!-- <mat-list-item routerLink="/approval-tool" routerLinkActive="active" (click)="side_redirect('approval-tool')" *ngIf="user_type=='C' || user_type=='CV'">   
    <img src="./assets/images/approval_icon.png" alt="" title="Approval Tool"><span>Approval Tool</span>
  </mat-list-item> -->

  <mat-list-item routerLink="/assets" routerLinkActive="active" (click)="side_redirect('assets')" *ngIf="user_type=='C' || user_type =='CA'">
    <!-- <mat-icon>view_module</mat-icon>  -->
    <img src="./assets/images/sni1.png" alt="" title="Asset Links"> <span>Asset Links</span>
  </mat-list-item>

  <mat-list-item routerLink="/job" routerLinkActive="active" (click)="side_redirect('job')">
    <!-- <mat-icon>view_module</mat-icon>  -->
    <img src="./assets/images/jobs_icon.png" alt="" title="jobs"><span>Jobs</span>
  </mat-list-item>
  <mat-list-item routerLink="/approval-tools" routerLinkActive="active" (click)="side_redirect('approval-tools')" *ngIf="user_type=='SA' || user_type=='CA'">
    <!-- <mat-icon>view_module</mat-icon>  -->
    <img src="./assets/images/approval_icon.png" alt="" title="Approval Tool"><span>Approval Tool</span>
  </mat-list-item>

  <mat-list-item routerLink="/user" routerLinkActive="active" (click)="side_redirect('user')"  *ngIf="user_type=='SA' || user_type=='CA' || user_type=='CV'">
    <img src="./assets/images/user_icon.png" alt="" title="Users"><span>User</span>
  </mat-list-item>

  <mat-list-item routerLink="/roles" routerLinkActive="active" (click)="side_redirect('roles')" *ngIf="user_type=='SA' || user_type=='CV'">
    <!-- <mat-icon>view_module</mat-icon>  -->
    <img src="./assets/images/user_role_icon.png" alt="" title="User Roles"><span>User Roles</span>
  </mat-list-item>

  <mat-list-item routerLink="/customer-mapping" routerLinkActive="active" (click)="side_redirect('customer-mapping/list')"  *ngIf="user_type=='SA' || user_type=='CA' || user_type=='CV'">
    <img src="./assets/images/customer_mapping_icon.png" alt="" title="Customer Mapping"><span>Customer Mapping</span>
  </mat-list-item>


  <mat-list-item routerLink="/vendor-mapping" routerLinkActive="active" (click)="side_redirect('vendor-mapping/list')"  *ngIf="user_type=='SA' || user_type=='CA' || user_type=='CV' || user_type=='C'">
    <img src="./assets/images/vender_mapping_icon.png" alt="" title="Vendor Mapping"><span>Vendor Mapping</span>
  </mat-list-item>

  <mat-list-item routerLink="/institution/list" routerLinkActive="active" (click)="side_redirect('institution/list')"  *ngIf="user_type=='SA'">
    <img src="https://cdn-icons-png.flaticon.com/512/66/66455.png?w=740&t=st=1690287969~exp=1690288569~hmac=84a60f6301fad17b00a8035bdd627dca86550b43bf044e0412cb5c93cd816076" alt="" title="Institution"><span>Institution</span>
  </mat-list-item>


  <mat-list-item routerLink="/dynamic-image-tag/list" routerLinkActive="active" (click)="side_redirect('dynamic-image-tag/list')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/image-tag.png" alt="" title="Dynamic Image Tag"><span>Dynamic Image Tag</span>
  </mat-list-item>

  <mat-list-item routerLink="/survey-group/list" routerLinkActive="active" (click)="side_redirect('survey-group/list')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/survey-group.png" alt="" title="Survey"><span>Survey Group</span>
  </mat-list-item>

  <mat-list-item routerLink="/survey/list" routerLinkActive="active" (click)="side_redirect('survey/list')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/survey-icon.png" alt="" title="Survey"><span>Survey</span>
  </mat-list-item>

  <mat-list-item routerLink="/customer-assign-service" routerLinkActive="active" (click)="side_redirect('customer-service-assign')"  *ngIf="user_type=='SA' || user_type=='CA' || user_type=='CV'">
    <img src="./assets/images/customer_mapping_icon.png" alt="" title="Customer Survey Assign"><span>Customer Survey Assign</span>
  </mat-list-item>

  <mat-list-item routerLink="/technician-survey-mapping" routerLinkActive="active" (click)="side_redirect('technician-survey-mapping')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/survey-group.png" alt="" title="Technician Survey Mapping"><span>Technician Survey Assign</span>
  </mat-list-item>

  <mat-list-item routerLink="/atm/manufacture-list" routerLinkActive="active" (click)="side_redirect('atm/manufacture-list')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/manufacture.png" alt="" title="Manufacture List"><span>ATM Manufacturer List</span>
  </mat-list-item>

  

  <mat-list-item routerLink="/atm/model-list" routerLinkActive="active" (click)="side_redirect('atm/model-list')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/modal.png" alt="" title="ATM Model List"><span>ATM Model List</span>
  </mat-list-item>

  

  <mat-list-item routerLink="/atm/location-list" routerLinkActive="active" (click)="side_redirect('atm/location-list')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/location.png" alt="" title="ATM Location List"><span>ATM Location List</span>
  </mat-list-item>

  
  <mat-list-item routerLink="/atm/atm-list" routerLinkActive="active" (click)="side_redirect('atm/atm-list')"  *ngIf="user_type=='SA'">
    <img src="./assets/images/atm.png" alt="" title="ATM List"><span>ATM List</span>
  </mat-list-item>


</mat-list>

